<template>
  <div>
    <HeaderImgOnlay
      baseUrl="./header/uboras-impressum"
      :sizes="[720,960,1440,1920,2880,3500]"
      onlayUrl="./header/headline-plain.svg"
      onlayWidth="33vw"
      onlayOffset="0px;"
      onlayLeft="33.5vw"
      :onlayText="$t('uboras.menu.impressumArch')"
    />
    <v-container class="mt-8">

      <v-row class="text-center">
        <!-- <v-col cols="12">
          <img
            :src="require('../assets/logo-dark.svg')"
            class="mt-2"
            style="width:50vw;"
          />
        </v-col>-->
        <v-col cols="12" sm="6" class="text-left">
          <h2>HSC Transport-Consult GmbH</h2>
      <p>
<b>{{$t('uboras.legal.corporatePurposeHdr')}}</b><br/>
            {{$t('uboras.legal.corporatePurposeBody')}}</p>
             <p><b>{{$t('uboras.legal.registerHdr')}}</b><br/> 
{{$t('uboras.legal.registerBody')}}</p> 
            <p><b>{{$t('uboras.legal.courtHdr')}}</b><br/>
                {{$t('uboras.legal.courtBody')}}</p>
            <p><b>{{$t('uboras.legal.seatHdr')}}</b><br/> 
                 {{$t('uboras.legal.seatBody')}}</p>
            <p><b>{{$t('uboras.legal.telMailHdr')}}</b><br/>
               <span v-html="$t('uboras.legal.telMailBody')"></span>
            </p>
            <p><b>{{$t('uboras.legal.membershipHdr')}}</b><br/> 
            {{$t('uboras.legal.membershipBody')}}</p>
            <p><b>{{$t('uboras.legal.authorityHdr')}}</b><br/>
            {{$t('uboras.legal.authorityBody')}}</p>
            <p><b>{{$t('uboras.legal.directorHdr')}}</b><br/> 
            {{$t('uboras.legal.directorBody')}}</p>
        </v-col>
        <v-col cols="12" sm="6" class="text-left">
          <h2>{{$t('uboras.legal.dataProtection')}}</h2>
          <p>{{$t('uboras.legal.dataProtectionOpener')}}</p>
<p><b>{{$t('uboras.legal.cookiesHdr')}}</b><br/>
{{$t('uboras.legal.cookiesBody')}}
    </p>
<p><b>{{$t('uboras.legal.contactHdr')}}</b><br/>
{{$t('uboras.legal.contactBody')}}
    </p>
<p><b>{{$t('uboras.legal.yourRightsHdr')}}</b><br/>
{{$t('uboras.legal.yourRightsBody')}}
    </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import HeaderImgOnlay from './HeaderImgOnlay';

  
  export default {
    name: 'Legal',

    components: {
      HeaderImgOnlay,
    },

    data: () => ({
    }),
  }
</script>
