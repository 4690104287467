<template>
  <div class="text-center">
    <HeaderImgOnlay
      baseUrl="./header/uboras-app"
      onlayUrl="./header/headline-plain.svg"
      onlayWidth="33vw"
      onlayOffset="0px;"
      onlayLeft="33.5vw"
      :onlayText="$t('uboras.menu.appArch')"
    />
    <v-container class="text-start">
      <v-row>
        <v-col cols="12">
          <h1 v-html="$t('uboras.demo.featuresServices')"></h1>
          <p v-html="$t('uboras.demo.orderNow')"></p>
        </v-col>
      </v-row>
      <v-row  class="text-center">
        <v-col cols="12" md="6">
    <PrettyImg baseUrl="./images/uboras-app" width="100%"/>
        </v-col>
        <v-col cols="12" md="6">
    <PrettyImg baseUrl="./images/uboras-app-survey" width="100%"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

  import HeaderImgOnlay from './HeaderImgOnlay';
  import PrettyImg from './PrettyImg';
  
  export default {
    name: 'App',

    components: {
      PrettyImg,
      HeaderImgOnlay,
    },

    data: () => ({
    }),
  }
</script>
