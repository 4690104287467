<template>
  <div>
    <HeaderImgOnlay
      baseUrl="./header/uboras-top"
      onlayUrl="./header/headline-plain.svg"
      onlayWidth="33vw"
      onlayOffset="0px;"
      onlayLeft="33.5vw"
      :onlayText="$t('uboras.menu.companiesArch')"
    />
    <v-container>

      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <a href="http://www.hsctransportconsult.com/" target="_blank">
              <v-img
                :src="require('../assets/logo-hsc.svg')"
                class="my-3"
                contain
                height="200"
              />
            </a>
            <v-card-title>hsc transport consult GmbH</v-card-title>
            <v-card-text class="text-start" v-html="$t('uboras.home.aboutHsc')">
            </v-card-text>            
            <v-card-text>
              <a href="http://www.hsctransportconsult.com/" target="_blank">{{$t('uboras.common.details')}}</a>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <a href="https://www.iteg.at/" target="_blank">
              <v-img
                :src="require('../assets/logo-iteg.svg')"
                class="my-3"
                contain
                height="200"
              />
            </a>
            <v-card-title>ITEG IT-Engineers GmbH</v-card-title>
            <v-card-text class="text-start" v-html="$t('uboras.home.aboutIteg')">
               
            </v-card-text>
            <v-card-text>
              <a href="https://www.iteg.at/" target="_blank">{{$t('uboras.common.details')}}</a>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import HeaderImgOnlay from './HeaderImgOnlay';

  
  export default {
    name: 'Companies',

    components: {
      HeaderImgOnlay,
    },

    data: () => ({
    }),
  }
</script>
