<template>
  <div style="line-height:0px;">
    <img style="max-width:100vw;"
     :src="imgSrc"
     :srcset="srcSet"
    />
    <div
      :style="{width:'100vw',position:'relative',height:onlayOffset}">
      <img :style="{width:onlayWidth,position:'absolute',left:onlayLeft,bottom:'0px'}"
        :src="onlayUrl"
      /> 
    </div>
    <div
      class="text-center"
      v-if="onlayText"
      :style="{color:'gray',lineHeight:'normal',fontSize:onlaySize}"
      v-html="onlayText">
    </div>
  </div>
</template>
<script>
import { supportsWebp } from '../lib/Util';

export default {
  name: 'HeaderImgOnlay',

  props: {
    sizes: {
      type: Array,
      default: () => [720,960,1440,1920,2880,3840]
    },
    baseSize: {
      type: Number,
      default: 1440
    },
    format: {
      type: String,
      default: 'webp'
    },
    fallbackFormat: {
      type: String,
      default: 'jpg'
    },
    baseUrl: {
      type: String,
      required: true
    },
    onlayUrl: {
      type: String,
      required: true          
    },
    onlayWidth: {
      type: String,
      required: true
    },
    onlayOffset: {
      type: String,
      required: true
    },
    onlayLeft: {
      type: String,
      required: true
    },
    onlaySize: {
      type: String,
      default: '4.5vw'
    },
    onlayText: {
      type: String,
      default: null
    }
  },

  computed: {
    realFormat: function() {
       return supportsWebp() ? this.format : this.fallbackFormat;
    },
    imgSrc: function() {
      return this.baseUrl + '-' + this.baseSize + 'px.' + this.realFormat;
    },
    srcSet: function () {
      const fmt =  this.realFormat;
      let urls = [];
      for (let i=0;i<this.sizes.length;++i) {
        const sz = this.sizes[i];
        const url = this.baseUrl + '-' + sz + 'px.' + fmt + ' ' + sz + 'w';
        urls.push(url);
      }
      return urls.join(', ');
    },
  }
}
</script>
