<template>
  <div>
    <HeaderImgOnlay
      baseUrl="./header/uboras-contact"
      onlayUrl="./header/headline-plain.svg"
      onlayWidth="33vw"
      onlayOffset="0px;"
      onlayLeft="33.5vw"
      :onlayText="$t('uboras.menu.contactArch')"
    />
    <v-container>

      <v-card>
        <v-card-text>
          <v-form v-model="formValid">
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field
                  v-model="subject"
                  :label="$t('uboras.contact.subject')"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="senderEmailAdr"
                  :label="$t('uboras.contact.email')"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>

              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="message"
                  rows="10"
                  :label="$t('uboras.contact.message')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="rejectMessage"
          >
            {{$t('uboras.common.cancel')}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmMessage"
            :disabled="!formValid"
          >
            {{$t('uboras.common.send')}}
          </v-btn>
        </v-card-actions>
      </v-card>
      
    </v-container>
  </div>
</template>

<script>
  import { makeInputRules } from '../lib/Util';
  import HeaderImgOnlay from './HeaderImgOnlay';

  export default {
    name: 'Product',

    components: {
      HeaderImgOnlay,
    },

    data: function() { return {
      formValid: false,
      subject: '',
      message: '',
      senderEmailAdr: '',
      rules: makeInputRules(this),
    }},

    methods: {
      confirmMessage() {
        console.log("Message confirmed suject=[",this.subject,"], senderEmailAdr=[",this.sendeEmailAdr,", message=[",this.message,"]");
        let url = "mailto:info@hsctransportconsult.com";
        url+="?subject="+encodeURIComponent(this.subject);
        url+="&reply-to="+encodeURIComponent(this.senderEmailAdr);
        url+="&body="+encodeURIComponent(this.message);
        window.open(url);
        this.rejectMessage();
      },
      rejectMessage() {
        this.subject = '';
        this.senderEmailAdr = '';
        this.message = '';
      }
    }
  }
</script>
