import de from 'vuetify/lib/locale/de'

export default {
  $vuetify: de,
  uboras: {
    common: {
      ok: "OK",
      save: "Speichern",
      send: "Senden",
      cancel: "Abbrechen",
      name: "Name",
      email: "E-Mail Adresse",
      comment: "Kommentar",
      folder: "Verzeichnis",
      details: "Mehr Informationen\u2026"
    },
    input: {
      required: "Mussfeld.",
      invalidEMail: "Ungültige e-mail Adresse.",
    },
    contact: {
      subject: "Betreff",
      email: "E-Mail Adresse",
      message: "Ihre Nachricht an uns"
    },
    locales: {
      de: "Deutsch",
      en: "Englisch",
    },
    menu: {
      product: "Home",
      app: "App",
      appArch: "app<b>lication</b>",
      companies: "Firmen",
      companiesArch: "ko<b>operation</b>",
      contact: "Kontakt",
      contactArch: "kon<b>takt</b>",
      impressum: "Impressum",
      impressumArch: "im<b>pressum</b>",
    },
    header: {
      vision: "Vision",
      mission: "Mission",
      motto: "Leitspruch",
    },
    home: {
      vision: "Quality in Public Transport with Digital Solutions",
      mission: "Your ContRolling Systems",
      motto: "Wir sind Ihr Partner für digital mobile Lösungen zur Qualitätserhebung, Sicherung- und Optimierung in komplexen infrastrukturtechnischen Umgebungen. Qualität in Öffentlichen Verkehrsmittel!",
      aboutIteg: "<p>mit Firmensitz in Innsbruck ist Spezialist in der Entwicklung plattformunabhängiger technischer Software (Open Source-Technology) für Infrastrukturanbieter im Bereich von Talsperren-Analysesystemen, Autobahn-Mautsystemen sowie Webhosting-Anbieter mit Consultingdienstleistung für (Web)Agenturen, Unternehmen und Einzelpersonen sowie Experte für Open-Source-Technologien.</p><p>ITEG bietet besonderes Know-how in der Informationssicherheit –ISO-27001 Zertifizierung der Firma und der Geschäftsführer – sowie in dem Projektmanagement – cPM-Zertifizierung der Projektmanagerin.</p>",
      aboutHsc: "<p>mit Firmensitz in Innsbruck ist Spezialist im ÖV-Management und Consultant für Transportunternehmen und bietet ein All-Inklusive-Angebot im Bereich der Entwicklung der Unternehmens- und Tarifstrategie, der Liniennetz-, Fahr- und Dienstplanung sowie Leistungen im Bereich Marketing und Schulung (Fachbereich Kundenorientierung und ökonomische Fahrweise).</p><p>HSC ist Ihr Ansprechpartner für RIBAS® (Fahrerassistenzsystem von MIX Telematics, welches der Reduktion von Stress sowie Treibstoff-, Betriebs- und Versicherungskosten dient). HSC hat RIBAS® bei aktuell 14 Kunden in der Schweiz, Österreich, Fürstentum Liechtenstein und Südtirol zum Einsatz gebracht. Weiters ist HSC Repräsentant für SHOTL® (innovative On-Demand Plattform für Bedarfsverkehre) in Österreich, der Schweiz, Fürstentum Liechtenstein, Bayern und Baden-Württemberg.</p>",
      whatIsUboras: "Was ist Uboras?",
      aboutUboras: "<b><i>„Ubora“</i></b> steht in Suaheli für Qualität. Uboras® ist zum einen eine Software-Familie, welche das Qualitätsmanagement im Öffentlichen Verkehr mittels Handy-App unterstützt (Qualitätskontrollen, Fahrgasterhebungen, Fahrgastzählungen, Ticketkontrollen), zum anderen die physische Dienstleistung der Qualitätskontrollen (Mystery-Shopper, Fahrgasterhebungen und Zählungen, etc.).",
      aboutUboras2: "Die App bietet modular erweiterbare Grundfunktionen und basiert auf Open Source Technologien mit hoch spezialisierter Software-Entwicklung, die sich in Großprojekten langjährig bewährt hat. Ihre Anforderungen können individuell durch Fahranalysen, Consulting, Konzeption, Testing etc. exakt bestimmt, technisch entwickelt und mit sämtlichen Serverdienstleistungen ergänzt werden. "
    },
    demo: {
      featuresServices: "Features und Services", 
      orderNow: "<p>Haben Sie Fragen? Kontaktieren Sie uns bitte kurz per <a href='#/contact'>Kontakt-Formular</a> oder telefonisch. Helmuth Schröttner kümmert sich persönlich um Ihr Anliegen und demonstriert die Uboras-Produktpalette.</p><p>Die Uboras-App steht für Analyse und Diagnose der Qualität der Service-Dienstleistungen in öffentlichen Verkehrsmitteln. Individuell einstellbare Features ermöglichen ein gezieltes, kontrollierbares und steuerbares Erfassen, Exportieren, Speichern und Auswerten erhobener Daten.</p>",
      orderNowLink: "Jetzt anfragen."
    },
    legal: {
      holder:"Domain-Inhaber",
      projectManagement: "Projektmanagement",
      artDirection: "Art Direction",
      corporatePurposeHdr: "Unternehmensgegenstand",
      corporatePurposeBody: "Beratung im Bereich Verkehr und Betriebsplanung, Handel",
      registerHdr: "UID Nr. und Firmenbuch-Nr.",
      registerBody: "ATU 70324507 und FN4464589",
      courtHdr: "Firmenbuch-Gericht",
      courtBody: "Landesgericht Innsbruck",
      seatHdr: "Firmensitz",
      seatBody: "Wallpachgasse 10, 6020 Innsbruck, Österreich",
      telMailHdr: "Tel. und E-Mail",
      telMailBody: "<a href='tel:+436765168246'>+43 676 5168246</a> und <a href='mailto:info@hsctransportconsult.com'>info@hsctransportconsult.com</a>",
      membershipHdr: "Mitgliedschaften",
      membershipBody: "WKÖ, WK Tirol, Fachgruppen Consulting und Handel",
      authorityHdr: "Aufsichts- und Gewerbehörde",
      authorityBody: "Magistrat Innsbruck",
      directorHdr: "Geschäftsführer",
      directorBody: "Helmuth Schröttner",
      dataProtection: "Datenschutzerklärung",
      dataProtectionOpener: "Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003, DSG).",
      cookiesHdr: "Cookies",
      cookiesBody: "Unsere Website speichert keine Cookies.",
      contactHdr: "Kontakt",
      contactBody: "Wenn Sie per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.",
      yourRightsHdr: "Ihre Rechte",
      yourRightsBody: "Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.",
    }
  }
}
