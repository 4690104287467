<style>
  .uborasMenu .v-tab {
    font-weight: bold;
  }
  .uboras.uborasTransparent.v-application {
    background-color: transparent;
  }
</style>
<template>
  <v-app :class="{ uboras: true, uborasTransparent: isTransparent }">
    <v-app-bar
      app
      color="secondary"
      dark
    >
      <v-app-bar-nav-icon class="d-md-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer  class="d-md-none"></v-spacer>

      <a href="#/home">
        <v-img
          alt="Uboras Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo-light.svg')"
          transition="scale-transition"
        />
      </a>

      <v-spacer></v-spacer>

      <v-tabs class="d-none d-md-block uborasMenu">
        <v-tab v-for="tab in pages"
          v-bind:to="tab.route"
          v-bind:key="tab.id">
          {{$t(tab.name)}}
        </v-tab>
      </v-tabs>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
            v-bind="attrs"
            v-on="on">
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="currentLocale">
            <v-list-item
              v-for="locale in locales"
              :key="locale.id"
              :value="locale.id"
            >
              <v-list-item-title>{{$t(locale.name)}}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
        >
          <v-list-item
            v-for="tab in pages"
            v-bind:key="tab.id"
            v-bind:to="tab.route">
            <v-list-item-title>{{$t(tab.name)}}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>


    <v-main>
    <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: function() { return {
    locales: [
      { "id": "en",
        "name": "uboras.locales.en"
      },
      { "id": "de",
        "name": "uboras.locales.de"
      }
    ],
    currentLocale: this.$i18n.locale,
    pages: [
       { "id": "product",
         "name": "uboras.menu.product",
         "route": "/home"
       },
       { "id": "app",
         "name": "uboras.menu.app",
         "route": "/app"
       },
       { "id": "companies",
         "name": "uboras.menu.companies",
         "route": "/companies"
       },
       { "id": "contact",
         "name": "uboras.menu.contact",
         "route": "/contact"
       },
       { "id": "impressum",
         "name": "uboras.menu.impressum",
         "route": "/impressum"
       },
     ],
     drawer: false
  }},
  computed: {
      isTransparent() {
        const m =  this.$router.currentRoute.matched;
        return m.length && m[0].props && m[0].props.default && m[0].props.default.transparent;
      },
  },
  mounted: function() {
    console.log("Uboras started.");
  },
  watch: {
    currentLocale (val) {
      console.log("Locale changed to [",val,"].");
      location.search="?locale="+val;
    },
  },
};
</script>
