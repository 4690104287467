import en from 'vuetify/lib/locale/de'

export default {
  $vuetify: en,
  uboras: {
    common: {
      ok: "OK",
      save: "Save",
      send: "Send",
      cancel: "Cancel",
      name: "Name",
      email: "e-mail address",
      comment: "Comment",
      folder: "Folder",
      details: "More information\u2026"
    },
    input: {
      required: "Required.",
      invalidEMail: "Invalid e-mail address."
    },
    contact: {
      subject: "Subject",
      email: "e-mail Address",
      message: "Your message to us"
    },
    locales: {
      de: "German",
      en: "English",
    },
    menu: {
      product: "Home",
      app: "App",
      appArch: "app<b>lication</b>",
      companies: "Companies",
      companiesArch: "co<b>operation</b>",
      contact: "Contact",
      contactArch: "con<b>tact</b>",
      impressum: "Legal Notice",
      impressumArch: "legal<b>notice</b>",
    },
    header: {
      vision: "Vision",
      mission: "Mission",
      motto: "Motto",
    },
    home: {
      vision: "Quality in Public Transport with Digital Solutions",
      mission: "Your ContRolling Systems",
      motto: "We are your partner for digital mobile solutions for quality assessment in complex infrastructures. Quality for Public Transport!",
      aboutIteg: "<p>located in Innsbruck Austria is specialized in the development of open-source based, platform independent Software for infrastructure provider in the fields of Dam Monitoring and Electronic Fare Collection. It provides Webhosting and Consulting Services for Web-Entrprises and individuals.</p><p>ITEG's expertise in Open-SourceTechnologies an Information Security is reflected by its ISO-27001 Certification. This certification is complemented by ISMS manager certificates of its managing directors and a cPM certification of the Head of Project Management.</p>",
      aboutHsc: "<p>headquartered in Innsbruck, Austria, is a specialist in public transport management and consultant for transport companies and offers an all-inclusive offer in the area of developing corporate and tariff strategies, route network, journey and duty planning as well as services in the area of marketing and training (specialist area customer orientation and economical driving style).</p><p>HSC is your contact for RIBAS® (driver assistance system from MIX Telematics, which serves to reduce stress as well as fuel, operating and insurance costs). HSC has currently used RIBAS® for 14 customers in Switzerland, Austria, the Principality of Liechtenstein and South Tyrol.</p><p>HSC is also a representative for SHOTL® (innovative platform for on-demand transport) in Austria, Switzerland, the Principality of Liechtenstein, Bavaria and Baden-Württemberg.</p>",
      whatIsUboras: "What is Uboras?",
      aboutUboras: "<b><i>„Ubora“</i></b> stands for quality in Suaheli. Uboras® is, on the one hand, a software family that supports quality management in public transport using a mobile phone app (quality controls, passenger surveys, passenger counts, ticket controls) and, on the other hand, the physical service of quality controls (mystery shoppers, passenger surveys and counts, etc.).",
      aboutUboras2: "The app offers modular, expandable basic functions and is based on open source technologies with highly specialized software development that has proven itself over many years in large projects. Your requirements can be precisely determined, technically developed and supplemented with all server services using driving analyzes, consulting, conception, testing, etc."
    },
    demo: {
      featuresServices: "Features and Services", 
      orderNow: "<p>Do you have any questions? Please contact us via the <a href='#/contact'>contact form</a> or via phone. Helmuth Schröttner will personally take care of your request and demonstrate to you the Uboras product family.</p><p>The Uboras Application empowers you to analyse and diagnose the quality of services in public transport. Individually crafted features allow for concise, traceable and focused collection, export and assessment of gathered data.</p>",
      orderNowLink: "Order now."
    },
    legal: {
      holder:"Domain Holder",
      projectManagement: "Project Management",
      artDirection: "Art Direction",
      corporatePurposeHdr: "Coroporate Purpose",
      corporatePurposeBody: "Consulting in the field of public transport, trade",
      registerHdr: "VAT ID and Company Register ID",
      registerBody: "ATU 70324507 and FN4464589",
      courtHdr: "Court in Charge",
      courtBody: "Federal Court of Innsbruck",
      seatHdr: "Headquarters",
      seatBody: "Wallpachgasse 10, 6020 Innsbruck, Österreich",
      telMailHdr: "Tel. and e-mail",
      telMailBody: "<a href='tel:+436765168246'>+43 676 5168246</a> and <a href='mailto:info@hsctransportconsult.com'>info@hsctransportconsult.com</a>",
      membershipHdr: "Memberships",
      membershipBody: "Austrian Chamber of Commerce, Chamber of Commerce of Tyrol, Sections Consulting and Trade",
      authorityHdr: "Supervisory Authority",
      authorityBody: "Magistrate of Innsbruck",
      directorHdr: "CEO",
      directorBody: "Helmuth Schröttner",
      dataProtection: "Data Protection",
      dataProtectionOpener: "The protection of your personal data is very important to us. We therefore process your data exclusively on the basis of the statutory provisions (DSGVO, TKG 2003, DSG)",
      cookiesHdr: "Cookies",
      cookiesBody: "Our website does not store cookies.",
      contactHdr: "Contact",
      contactBody: "If you contact us by email, the data you provide will be stored by us for the purpose of processing the request and in case of follow-up questions. We do not pass on this data without your consent.",
      yourRightsHdr: "Your Rights",
      yourRightsBody: "In principle, you have the right to information, correction, deletion, restriction, data portability, revocation and objection. If you believe that the processing of your data violates data protection law or your data protection claims have been violated in any other way, you can complain to the supervisory authority. In Austria this is the data protection authority.",

    }
  }
}
