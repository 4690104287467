<template>
  <img style="max-width:100vw;"
     :src="imgSrc"
     :srcset="srcSet"
    />
</template>
<script>
import { supportsWebp } from '../lib/Util';

export default {
  name: 'HeaderImg',

  props: {
    sizes: {
      type: Array,
      default: () => [720,960,1440,1920,2880,3840]
    },
    baseSize: {
      type: Number,
      default: 1440
    },
    format: {
      type: String,
      default: 'webp'
    },
    fallbackFormat: {
      type: String,
      default: 'jpg'
    },
    baseUrl: {
      type: String,
      required: true
    },
  },

  computed: {
    realFormat: function() {
       return supportsWebp() ? this.format : this.fallbackFormat;
    },
    imgSrc: function() {
      return this.baseUrl + '-' + this.baseSize + 'px.' + this.realFormat;
    },
    srcSet: function () {
      const fmt =  this.realFormat;
      let urls = [];
      for (let i=0;i<this.sizes.length;++i) {
        const sz = this.sizes[i];
        const url = this.baseUrl + '-' + sz + 'px.' + fmt + ' ' + sz + 'w';
        urls.push(url);
      }
      return urls.join(', ');
    },
  }
}
</script>
