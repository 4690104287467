<template>
  <div>
    <HeaderImg baseUrl="./header/uboras-top"/>
    <v-container>

      <v-row>
        <v-col cols="12" class="d-none d-md-block text-center">
          <img
            :src="require('../assets/logo-dark.svg')"
            class="my-3"
            style="width:50vw;"
          />
        </v-col>
        <v-col cols="12">
          <h1 v-html="$t('uboras.home.vision')"></h1>
        </v-col>
   
        <v-col cols="12">
          <p class="text-left" v-html="$t('uboras.home.aboutUboras')"></p>
          <p class="text-left" v-html="$t('uboras.home.aboutUboras2')"></p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import HeaderImg from './HeaderImg';

  
  export default {
    name: 'Product',

    components: {
      HeaderImg,
    },

    data: () => ({
    }),
  }
</script>
