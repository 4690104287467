import Vue from 'vue';
import VueI18n from 'vue-i18n'

import en from '../i18n/en'
import de from '../i18n/de'

Vue.use(VueI18n);


const messages = { en,de } 
const urlParams = new URLSearchParams(window.location.search);
const urlLocale = urlParams.get('locale');

let locale;

if (urlLocale in messages) {
  locale = urlLocale;
}
else {
  const browserLocale = navigator.language.replace(/-.*/,'');

  if (browserLocale in messages) {
    locale = browserLocale;
  }
  else {
    locale = "de";
  }
}

console.log("Initializing with locale [",locale,"]."); 

document.documentElement.lang=locale;

// Create VueI18n instance with options
export default new VueI18n({
  locale,
  messages
})
