<template>
  <img :style="{maxWidth:maxWidth,width:width}"
     :src="imgSrc"
     :srcset="srcSet"
    />
</template>
<script>
export default {
  name: 'PrettyImg',

  props: {
    sizes: {
      type: Array,
      default: () => [1,2]
    },
    width: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '100vw',
    },
    baseSize: {
      type: Number,
      default: 1
    },
    format: {
      type: String,
      default: 'webp'
    },
    fallbackFormat: {
      type: String,
      default: 'jpg'
    },
    baseUrl: {
      type: String,
      required: true
    },
  },

  computed: {
    realFormat: function() {
       return window.navigator.userAgent.indexOf('Trident/') > 0 ? this.fallbackFormat : this.format;
    },
    imgSrc: function() {
      return this.baseUrl + '-' + this.baseSize + 'px.' + this.realFormat;
    },
    srcSet: function () {
      const fmt =  this.realFormat;
      let urls = [];
      for (let i=0;i<this.sizes.length;++i) {
        const sz = this.sizes[i];
        const url = this.baseUrl + '-' + sz + 'x.' + fmt + ' ' + sz + 'x';
        urls.push(url);
      }
      return urls.join(', ');
    },
  }
}
</script>
