import VueRouter from 'vue-router'
import Vue from 'vue'

import Legal from '../components/Legal';
import Product from '../components/Product';
import Companies from '../components/Companies';
import Contact from '../components/Contact';
import App from '../components/App';

Vue.use(VueRouter)

const routes = [
  { path: "/",
    redirect: "/home",
  },
  { path: '/home',
    component: Product
  },
  { path: '/app',
    component: App
  },
  { path: '/companies',
    component: Companies
  },
  { path: '/contact',
    component: Contact,
    props: { transparent: true }
  },
  { path: '/impressum',
    component: Legal
  }
]

export default new VueRouter({
  routes
})
